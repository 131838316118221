'use client';

import React, { useState } from 'react';
import { Flex } from '@mezzoforte/forge';
import {
  Bank,
  BarchartDown,
  City,
  Finland,
  HourglassHigh,
  Megaphone,
  Metsahallitus,
  Package,
  Puolustusvoimat,
  Scale,
} from '@mezzoforte/forge-icons';
import { categoriesStaticData } from '@/util/categoryClientData';
import { useCategories } from '@/hooks/useCategories';
import AccordionItem, { CategoryAccordionItem } from '@/components/Category/AccordionItem';
import { splitToChunks } from '@/util/array';

function CategoryAccordion() {
  const [accordionItemOpen, setAccordionItemOpen] = useState<string | null>(null);
  const { data } = useCategories();

  if (!data) return null;

  const items: CategoryAccordionItem[] = [
    { name: 'Maakunnittain', icon: Finland, href: '/maakunnat' },
    ...data.categories.map(({ id, htmlName, children }) => ({
      name: htmlName,
      icon: categoriesStaticData.get(id)?.icon ?? Package, // Default for categories not supported by frontend
      href: `/osasto/${categoriesStaticData.get(id)?.slug ?? ''}`,
      children:
        Object.entries(children).length > 0
          ? Object.entries(children)
              .sort(([_idA, a], [_idB, b]) => a.order - b.order)
              .map(([id, child]) => ({
                name: child.htmlName,
                href: `/osasto/${categoriesStaticData.get(Number(id))?.slug ?? ''}`,
              }))
          : undefined,
    })),
    {
      name: 'Perinteiset huutokaupat',
      icon: Megaphone,
      href: '/perinteiset-huutokaupat',
    },
    {
      name: 'Ulosotto',
      icon: Scale,
      href: '/ilmoittaja/ulosotto',
    },
    {
      name: 'Konkurssi&shy;pesät',
      icon: BarchartDown,
      href: '/ilmoittaja/konkurssipesat',
    },
    {
      name: 'Puolustus&shy;voimat',
      icon: Puolustusvoimat,
      href: '/ilmoittaja/puolustusvoimat',
    },
    {
      name: 'Metsä&shy;hallitus',
      icon: Metsahallitus,
      href: '/ilmoittaja/metsahallitus',
    },
    {
      name: 'Rahoitus&shy;yhtiöt',
      icon: Bank,
      href: '/ilmoittaja/rahoitusyhtiot',
    },
    {
      name: 'Julkinen sektori',
      icon: City,
      href: '/ilmoittaja/julkinen-sektori',
    },
    {
      name: 'Päättyvät',
      icon: HourglassHigh,
      href: '/osasto',
    },
  ];

  return (
    <Flex as="ul" p={0} flexDirection={{ base: 'column', lg: 'row' }} style={{ columnGap: '24px' }}>
      {splitToChunks(items, 3).map((itemChunk, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Flex key={index} listStyleType="none" flexDirection="column" flexGrow={1} width={{ lg: '310px', xl: '350px' }}>
          {itemChunk.map((item) => (
            <AccordionItem
              key={item.name}
              item={item}
              toggleOpen={() => setAccordionItemOpen(accordionItemOpen !== item.name ? item.name : null)}
              isOpen={accordionItemOpen === item.name}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
}

export default CategoryAccordion;
